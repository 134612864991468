<template lang="pug">
v-dialog(
  :value="value",
  @input="$emit('input', $event)",
  :width="width",
  :transition="transition"
  @click:outside="$emit('click:outside', $event)",
  scrollable
)
  template(v-slot:activator="{ on }")
    slot(name="dialog-action")
  .dialog-wrapper
    .dialog-head
      span.dialog-title 
        slot(name="title") {{ title }}
      app-button(@click.native="$emit('input', false)", icon)
        i.material-icons close

    .dialog-body.hide-scrollbar
      slot(name="dialog-body")

    .dialog-footer(v-if="$slots['dialog-footer']")
      slot(name="dialog-footer")
</template>

<script>
/*
 TODO: IN FUTURE NEED TO DEPRECATE VUETIFY IN TEMPLATE
*/
import appButton from "@/components/global/actions/BaseButton.vue";

export default {
  name: "crmDialog",

  components: {
    appButton,
  },

  props: {
    width: {
      type: String,
      default: "900px",
    },
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    transition: String
  },
};
</script>

<style lang="scss">
.crm-dialog {
  position: relative;

  .crm-dialog-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    border-color: rgb(33, 33, 33);
    background-color: rgb(33, 33, 33);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;

    &.visible {
      z-index: 1000;
      opacity: 0.65;
      transition: opacity 0.3s ease-in-out;
      pointer-events: all;
    }
  }

  .crm-dialog__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1001;
    display: block;
    overflow: auto;
    min-width: 300px;
    transform: translate(-50%, -50%);
  }
}

.dialog-head {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  height: 50px;
  border-bottom: 1px solid $border-color;
  border-radius: 8px 8px 0 0;
  background-color: #feffff;

  .dialog-title {
    color: #494b53;
    letter-spacing: 0;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-size: 16px;
    line-height: normal;
  }

  .icon-close {
    display: flex;
    width: 29px;
    height: 29px;
    transition: all 0.3s ease-in-out;
    transform: scale(0.95);
    cursor: pointer;

    &:hover {
      transform: scale(1.25);
    }
  }
}

.dialog-body {
  overflow-y: auto;
  flex: 1 1 auto;
  background-color: $body-bg;
  box-shadow: inset 0 -1px 4px 0 rgba(0, 0, 0, 0.06);
}

.dialog-footer {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px 15px;
  height: 50px;
  border-top: 1px solid $border-color;
  border-radius: 0 0 8px 8px;
  background-color: #feffff;
}

.dialog-wrapper {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  background-color: $white;
}
</style>
